import { useState } from 'react'
import styles from './auth.scss'
import { OldModal as Modal } from 'components/modal'
import { withGlobal } from 'components/global'
import dynamic from 'next/dynamic'

const ForgotPasswordScreen = dynamic(() => import('./forgot_password'))
const RegisterScreen = dynamic(() => import('./register'))
const LoginScreen = dynamic(() => import('./login'))
const ProviderScreen = dynamic(() => import('./provider'))
const LogoutScreen = dynamic(() => import('./logout'))

const AuthModal = ({ authModalOpen, activeAuthScreen, toggleAuth, authOptions }) => {
  const [userType, asUserType] = useState('user')

  return (
    <>
      <Modal
        isOpen={authModalOpen}
        disableClosing={authOptions?.disableModalClosing}
        onRequestClose={toggleAuth}
        className="auth-modal">
        {activeAuthScreen === 'login' && (
          <LoginScreen asUserType={asUserType} authOptions={authOptions} />
        )}
        {activeAuthScreen === 'register' && <RegisterScreen authOptions={authOptions} />}
        {activeAuthScreen === 'forgotPassword' && (
          <ForgotPasswordScreen userType={userType} authOptions={authOptions} />
        )}
        {activeAuthScreen === 'provider' && (
          <ProviderScreen asUserType={asUserType} authOptions={authOptions} />
        )}
        {activeAuthScreen === 'logout' && <LogoutScreen authOptions={authOptions} />}
      </Modal>
      <style jsx global>
        {styles}
      </style>
    </>
  )
}

export default withGlobal(AuthModal)
